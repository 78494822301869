import { http } from '../utils/http';

const publicPath = '/api/driver-center/driver/mine';

export const apiGetTrailerList = async params => {
  return await http.post(`${publicPath}/trailer/searchTrailer`, params, false);
};

export const apiCheckTrailerNumber = async params => {
  return await http.post(`${publicPath}/trailer/verifyCarLicensePlate`, params, false);
};

export const apiAddTrailerByCarNumber = async params => {
  return await http.post(`${publicPath}/trailer/addCarLicensePlate`, params, false);
};

export const apiGetTrailerDetail = async params => {
  return await http.post(`${publicPath}/trailer/getTrailerById`, params, false);
};

export const apiTrailerAuth = async params => {
  return await http.post(`${publicPath}/trailer/trailerAuth`, params, false);
};

export const updateTrailerInfoApi = async params => {
  return await http.post(`${publicPath}/trailer/updateTrailerInfo`, params, false);
};

export const deleteTrailerByDriverApi = async params => {
  return await http.post(`${publicPath}/trailer/deletedTrailer`, params, false);
};
